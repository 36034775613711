import React, { Component } from 'react'
import EventsVirtual from '../components/EventsVirtual'
import Layout from '../layouts/Layout'

export default class eventsVirtual extends Component {
    render() {
        return (
            <Layout>
                <EventsVirtual /> 
            </Layout>

        )
    }
}
